@media screen and (max-width: 956px) {
  .client-background {
    width: 28% !important;
    padding: 15px 22px 15px 22px !important ;
  }
  .client-img {
    width: 100% !important ;
  }
  .client-imgVRR {
    height: 60%;
    text-align: center;
    max-width: 96px;
  }
}
