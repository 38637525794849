@media screen and (max-width: 1050px) {
  .center-container {
    justify-content: center !important;
  }
  .flex-reverse {
    flex-direction: column-reverse;
    margin-top: 0px !important;
  }
  .product-info {
    margin-bottom: 20px !important;
  }
  .booster-container {
    margin: 40px auto 0 auto !important;
  }
}

@media screen and (max-width: 1168px) {
  .about-container {
    margin: 340px auto 80px auto !important;
  }
  .header-product {
    margin-bottom: 40px !important;
  }
  .product-image {
    text-align: center;
  }
}

.testimonial-card {
  min-width: 400px;
  margin: auto 10px auto 0px;
  background: #ececec;
  border-radius: 10px;
  /* padding: 20px 5px; */
  color: #252521;
  max-width: 478px;
  flex: 1;
  overflow: hidden;
}

.pump-img {
  max-width: 450px;
  max-height: 520px;
}
.product-info {
  margin: 104px auto 110px auto;
}
.header-product {
  margin-bottom: 90px;
}
@media screen and (max-width: 662px) {
  .boosterpumps-container {
    margin-top: 10px;
    text-align: center;
  }
  .gallery-img {
    width: 380px !important ;
  }

  .heatpump-left-text {
    width: 150px !important ;
    padding-left: 20px !important ;
  }
}

@media screen and (max-width: 501px) {
  .water-upper-text {
    min-height: 35px;
  }
}

@media screen and (max-width: 757px) {
  .display-none {
    display: none;
  }

  .display-block {
    display: inline !important;
  }

  .large-text {
    font-size: 35px !important;
  }

  .hr-border {
    border-bottom: 1px solid gray !important ;
    padding-bottom: 17px !important;
  }

  .water-brighter-softer {
    padding-top: 20px !important ;
  }
}

.react-multi-carousel-dot-list {
  border-radius: 10px;
  align-items: center !important;
  bottom: -40px !important;
}

.react-multi-carousel-dot--active button {
  background: #ff8966 !important;
}

.react-multi-carousel-dot button {
  width: 40px !important;
  height: 8px !important;
  border-radius: 20px !important;
  background: #e7dfdd;
  margin-bottom: 5px !important;
  border: 0 !important;
}

.count-text {
  padding-left: 10px;
  padding-top: 9px;
  text-align: left;
  /* background: #eac3b5; */
}
.count-num {
  font-size: 39px;
  font-weight: 800;
}
.about-container {
  margin: 200px auto 110px auto;
}

@media screen and (max-width: 700px) {
  .count-num {
    font-size: 30px;
    text-align: center !important;
  }
  .pump-img {
    max-width: 300px;
  }
  .about-container {
    margin: 240px auto 40px auto !important;
  }
  .overlay-card-container {
    margin: 20px 50px !important;
  }
  .score-container {
    max-width: 89% !important ;
  }

  .count-text {
    /* border: 1px solid red; */
    font-size: 14px;
    text-align: center !important;
    max-width: 120px;
  }

  .about-exp {
    left: -10px !important ;
    top: 0px !important ;
    width: 120px;
  }

  .img-2 {
    width: 300px !important;
  }

  .send-inquiry {
    margin-bottom: 30px;
    margin-top: 30px !important ;
  }

  .water-arrow {
    right: -9px !important ;
    bottom: 0.5px !important ;
    /* border: 1px solid red; */
  }

  .whatsapp-container {
    right: 20px !important ;
    /* border: 1px solid red; */
  }

  .nav {
    width: 95.5% !important;
  }

  .nav-content {
    min-width: 120px;
    justify-content: space-evenly !important ;
  }

  .email {
    margin-right: 0px !important ;
  }
}

@media screen and (max-width: 760px) {
  .matchingpace {
    font-size: 15px !important ;
    border-radius: 28px;
  }
  .second-score-card {
    min-height: 85px;
    min-width: 120px;
  }

  .react-multi-carousel-dot button {
    width: 12px !important;
    height: 5px !important;
  }
}

.react-multi-carousel-item > img {
  width: 560px;
  height: 320px;
}

@media screen and (max-width: 375px) {
  .about-container {
    /* margin-bottom: 50px; */
    margin-top: 400px !important ;
  }
}

/* -----------  An   ----------- */

/* .react-multi-carousel-dot button {
  width: 50px;
  height: 8px !important;
  border-radius: 20px !important;
  background: #e7dfdd;
  background: #1f3ba9;
  margin-bottom: 5px !important;
  border: 0 !important;
} */

@media screen and (max-width: 1050px) {
  .center-container {
    justify-content: center !important;
  }
  .flex-reverse {
    flex-direction: column-reverse;
    margin-top: 0px !important;
  }
  .product-info {
    margin-bottom: 20px !important;
  }
  .booster-container {
    margin: 40px auto 0 auto !important;
  }
  .react-multi-carousel-item > img {
    width: 360px !important;
    height: 220px !important;
  }
}
@media screen and (max-width: 1168px) {
  .about-container {
    margin: 340px auto 80px auto !important;
  }
  .header-product {
    margin-bottom: 40px !important;
  }
  .product-image {
    text-align: center;
  }
}

.pump-img {
  max-width: 450px;
  max-height: 520px;
}
.product-info {
  margin: 104px auto 110px auto;
}
.header-product {
  margin-bottom: 90px;
}

@media screen and (max-width: 662px) {
  .boosterpumps-container {
    margin-top: 10px;
    text-align: center;
  }
  .gallery-img {
    width: 380px !important ;
  }

  .heatpump-left-text {
    width: 150px !important ;
    padding-left: 20px !important ;
  }
  .react-multi-carousel-item > img {
    width: 270px !important;
    height: 180px !important;
  }
}

@media screen and (max-width: 501px) {
  .water-upper-text {
    min-height: 35px;
  }
  .testimonial-card {
    min-width: 260px !important;
    width: 230px !important;
    margin: 0px 10px !important;
  }
  .testimonial-card > div {
    font-size: 16px !important;
  }
}

/* mobile */
@media screen and (max-width: 757px) {
  .large-text {
    font-size: 35px !important;
  }

  .hr-border {
    border-bottom: 1px solid gray !important ;
    padding-bottom: 17px !important;
  }

  .water-brighter-softer {
    padding-top: 20px !important ;
  }

  /* .react-multi-carousel-dot-list {
    display: none;
  } */
}

.display-block {
  display: none;
}

.react-multi-carousel-dot-list {
  background: #eac3b5;
  border-radius: 10px;
  height: 8px;
  align-items: center !important;
  bottom: -40px !important;
}

.react-multi-carousel-dot--active button {
  background: #ff8966 !important;
}

.count-text {
  padding-left: 10px;
  padding-top: 9px;
  text-align: left;
}
.count-num {
  font-size: 39px;
  font-weight: 800;
}
.about-container {
  margin: 200px auto 110px auto;
}

@media screen and (max-width: 700px) {
  .count-num {
    font-size: 30px;
    text-align: center !important;
  }
  .pump-img {
    max-width: 300px;
  }
  .about-container {
    margin: 240px auto 40px auto !important;
  }
  .overlay-card-container {
    margin: 20px 0px !important;
  }
  .score-container {
    max-width: 89% !important ;
  }

  .count-text {
    /* border: 1px solid red; */
    font-size: 14px;
    text-align: center !important;
    max-width: 120px;
  }

  .about-exp {
    left: -10px !important ;
    top: 0px !important ;
    width: 120px;
  }

  .img-2 {
    width: 300px !important;
  }

  .send-inquiry {
    margin-bottom: 30px;
    margin-top: 30px !important ;
  }

  .water-arrow {
    right: -9px !important ;
    bottom: 0.5px !important ;
    /* border: 1px solid red; */
  }

  .whatsapp-container {
    right: 20px !important ;
  }

  .nav {
    width: 95.5% !important;
  }

  .nav-content {
    min-width: 120px;
    justify-content: space-evenly !important ;
  }

  .email {
    margin-right: 0px !important ;
  }
}

@media screen and (max-width: 520px) {
  .matchingpace {
    font-size: 15px !important ;
    line-height: px !important ;
  }
}

.react-multi-carousel-item {
  /* width: unset !important; */
  margin-right: 20px !important ;
}
.react-multi-carousel-item > img {
  border-radius: 28px;
  width: 560px;
  height: 320px;
}

.ReactGridGallery_tile-viewport {
  height: '100%' !important;
  min-height: 320px;
}

.ReactGridGallery_tile-viewport img {
  height: unset !important;
  min-height: 320px;
  height: '100%' !important;
}

@media screen and (max-width: 662px) {
  .ReactGridGallery_tile-viewport img {
    /* min-width: 100%; */
  }

  .boosterpumps-container {
    margin-top: 10px;
    text-align: center;
  }
  .gallery-img {
    width: 380px !important ;
  }

  .heatpump-left-text {
    width: 150px !important ;
    padding-left: 20px !important ;
  }
  .react-multi-carousel-item > img {
    width: 280px !important;
    height: 180px !important;
  }
  .react-multi-carousel-item {
    width: 280px !important;
    margin-right: 10px !important;
  }
}

@media screen and (max-width: 501px) {
  .water-upper-text {
    min-height: 35px;
  }
  .testimonial-card {
    min-width: 280px !important;
    width: 280px !important;
    margin: auto 0px auto 0px;
  }
  .testimonial-card > div {
    font-size: 16px !important;
  }
}
