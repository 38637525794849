.content {
  display: none;
  transition: '0.5s linear';
}

#check {
  display: none;
}
#check:checked ~ .content {
  display: block;
}

#check:checked ~ .label1 {
  visibility: hidden;
}

#check:checked ~ .label1:after {
  content: 'Show Less';
  visibility: visible;
  /* display: block; */
}

.label1 {
  color: #ff8966;
}

.content2 {
  display: none;
  transition: '0.5s linear';
}

#check2 {
  display: none;
}
#check2:checked ~ .content2 {
  display: block;
}

#check2:checked ~ .label2 {
  visibility: hidden;
}

#check2:checked ~ .label2:after {
  content: 'Show Less';
  visibility: visible;
  /* display: block; */
}

.label2 {
  color: #ff8966;
}

/* @media screen and (max-width: 662px) {
  .geyser img {
    border: 1px solid red !important ;
  }
} */
