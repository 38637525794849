h1 {
  margin-bottom: 10px;
}

.main-background-img {
  background: rgba(0, 0, 0, 0.7) url('/public/banner-image.png');
  max-width: 100%;
  padding: 0;
  height: 750px;
  opacity: 0.8;
  display: flex;
  flex-direction: column;
  background-size: cover;
  position: relative;
  background-blend-mode: darken;
}

.react-multi-carousel-list {
  position: unset !important;
}

.react-multiple-carousel-arrow-left {
  left: 10px;
}
.customLeftArrowClass {
  color: orange !important ;
}

.react-multi-carousel-dot-list {
  max-width: 900px;
  margin-left: auto !important ;
  margin-right: auto !important ;
}

@media screen and (max-width: 662px) {
  .react-multi-carousel-dot-list {
    display: none !important ;
  }
}

.ReactGridGallery-tile {
  border: 1px solid red !important ;
  margin: 20px !important  ;
}
